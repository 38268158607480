<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseVideos.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseVideos.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseVideos = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Video.Items.includes('D')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader class="d-flex justify-content-between align-items-center">
          <strong>{{ $t('Navigation.Video/VimeoStore') }}</strong>
            <CButton color="success" size="sm" @click="UploadArea = !UploadArea">
                <CIcon name="cil-plus" size="sm" /> {{ $t('Media.VideoUpload') }}
            </CButton>
        </CCardHeader>
        <CCardBody class="p-0">
            <CCollapse :show="UploadArea">
                <div class="p-3">
                    <Dropzone
                            id="DropUpload"
                            ref="DropUpload"
                            :options="UploadOptions"
                            :useCustomSlot="true"
                            class="mb-3"
                            @vdropzone-success="CompleteUpload"
                    >
                        <CIcon name="cil-video" class="c-icon-custom-size" size="lg" />
                        <span class="d-block">{{ $t('Global.DragAndUpload') }}</span>
                    </Dropzone>
                </div>
            </CCollapse>
          <CDataTable
              id="PostList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="NoItemsView"
              responsive
              hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-Video-' + item.VideoID" :value="item.VideoID" v-model="ChooseVideos">
                </label>
              </td>
            </template>
            <template #Cover="{item}">
              <td>
                <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="StatusTypeColor[item.Status]">
                  {{ $t('Video/VimeoStore.StatusType.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{index}">
              <td>
<!--                <CButton v-if="$store.state.user.permission.Permission.Video.Items.includes('W')" color="info" size="sm" class="mr-1" @click="$router.push(`/post/detail/${item.VideoID}`)">-->
<!--                  <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/> {{ $t('Video/VimeoStore.Edit') }}-->
<!--                </CButton>-->
                <CButton color="dark" size="sm" class="mr-1" @click="ChooseSingleVideo(index)">
                  <CIcon name="cil-airplay" class="c-icon-custom-size mr-1"/> {{ $t('Video/VimeoStore.Embed') }}
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="EmbedModel" class="EmbedModel" :show.sync="EmbedModel" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Video/VimeoStore.EmbedVideo') }}</h5>
          <CButtonClose @click="EmbedModel = false" />
        </header>
      </template>
      <h6>{{ $t('Video/VimeoStore.Iframe') }}</h6>
      <div class="bg-light p-3 mb-3">
        <code>{{ NowChooseVideo.Embed.html }}</code>
      </div>
      <h6>{{ $t('Video/VimeoStore.Link') }}</h6>
      <div class="bg-light p-3">
        <code>https://player.vimeo.com/{{ NowChooseVideo.URI }}</code>
      </div>
      <template #footer-wrapper>
        <footer class="modal-footer border-top-0">
          <CButton @click="EmbedModel = false" block color="light">{{ $t('Global.Confirm') }}</CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "Vimeo影音庫"
  }
}
</route>

<script>
export default {
  name: 'VimeoStore',
  layout: 'manage',
  components: {
    Dropzone: () => import('@/plugins/dropzone')
  },
  data () {
    return {
      List: [],
      NowChooseVideo: {
        Embed: {}
      },
      SelectCategories: [],
      AlertModel: false,
      EmbedModel: false,
      ChooseVideos: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'create_nto`',
      UploadArea: false,
      Loading: false,
      Submit: false,
      UploadOptions: {
        url: '/api/media/vimeo/upload',
          params: {
              Domain: this.$store.state.user.permission.Domain,
              VimeoFolder: this.$store.state.user.permission.VimeoFolder || null
          },
          headers: {
              'xx-csrf-token': localStorage.getItem('XX-CSRF-Token')
          },
        maxFilesize: 100, // MB
        addRemoveLinks: true,
          paramName: 'upload'
      },
      NoItemsView: {
        noResults: this.$t('Video/VimeoStore.NoResults'),
        noItems: this.$t('Video/VimeoStore.NoItems')
      },
        StatusTypeColor: {
            "available": "success",
            "unavailable": "danger",
            "transcoding": "info",
            "transcode_starting": "info",
            "uploading": "warning"
        },
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Cover', label: this.$t('Video/VimeoStore.Cover') },
        { key: 'VideoID', label: this.$t('Video/VimeoStore.VideoID') },
        { key: 'Name', label: this.$t('Video/VimeoStore.Name') },
        { key: 'URI', label: this.$t('Video/VimeoStore.URI') },
        { key: 'CreateTime', label: this.$t('Video/VimeoStore.CreateTime') },
        { key: 'Status', label: this.$t('Video/VimeoStore.Status') },
        { key: 'Action', label: '' }
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetStoreList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetStoreList() {
      this.Loading = true
      const params = {}
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      if (PermissionSession.VimeoFolder) {
        params.folderId = PermissionSession.VimeoFolder
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/media/vimeo/store',
        method: 'get',
        params
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      this.$router.replace({
        path: '/vimeo/store',
        query
      }).then(() => {
        this.Init()
      })
    },
    ChooseSingleVideo(Index) {
      this.EmbedModel = true
      this.NowChooseVideo = this.List[Index]
    },
    CompleteUpload() {
      this.$refs.DropUpload.removeAllFiles(true)
      this.GetStoreList()
    },
    Delete() {
        this.Loading = true
        return this.$store.dispatch('InnerRequest', {
            url: '/media/vimeo/delete/',
            method: 'post',
            data: {
                VideoIDs: this.ChooseVideos
            }
        }).then(() => {
            this.Loading = false
            this.ResetState()
            this.GetStoreList()
        }).catch((err) => {
            this.Loading = false
            this.ResetState()
            this.$notify({
                group: 'notify',
                title: this.$t('Message.Error'),
                text: this.$t('Message.Global/ErrorMessage') + err.msg,
                type: (err.type ? err.type : 'error')
            })
        })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseVideos = this.List.map(data => {
          return data.VideoID
        })
      } else {
        this.CheckAllValue = false
        this.ChooseVideos = []
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseVideos = []
    },
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#PostList thead th:first-child {
  text-align: center;
}
#PostList tbody td:first-child {
  vertical-align: middle;
}
</style>
